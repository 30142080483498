<template>
    <div class="slot-attend">
        <div class="content" v-if="currentSlot">  
            <SlotAttendForm
                :eventSlot="currentSlot"
                :slotIsFirst="slotIsFirst()"
                :slotIsLast="slotIsLast()"
                :onSwipeLeft="swipeLeftHandler"
                :onSwipeRight="swipeRightHandler"
            />
        </div>
    </div>
</template>

<script>
import SlotAttendForm from '../components/forms/slot-attend/SlotAttendForm.vue'
import router from '../router'
import { convertTZ } from '../scripts/date'
import Slot from '../scripts/slot'
import CMS from '../service/cms/service'
import YYYYMMDD from '../scripts/date'
export default {
    name: 'SlotAttend',
    components: {
        SlotAttendForm
    },
    computed: {
		calendarUid(){
			return this.$route.params.calendarUid
		},

		calendarTimezone(){
			let timezone = this.$store.getters.getTimezone
            if (timezone && this.currentSlot == null) this.updateCurrentSlot()
			return this.$store.getters.getTimezone
		},

		browserTimezone(){
            let timezone = this.$store.getters.browserTimezone
            if (timezone && this.currentSlot == null) this.updateCurrentSlot()
            return timezone
		},
    },
    data() {
        return {
            currentSlot: null,
            eventSlotIsRequested: false,
            slotsWithSameDate: [],
        };
    },

    watch: {
        '$route.params.slotId': {
            handler: function () {
				this.eventSlotIsRequested = false
				let browserTzIsValid = this.browserTimezone && this.browserTimezone.id != -1
				let calendarTzIsValid = this.calendarTimezone && this.calendarTimezone.id != -1

				if (browserTzIsValid && calendarTzIsValid) this.updateCurrentSlot()
            },
            deep: true,
            immediate: true
        },
    },
    created(){
        this.updateCurrentSlot()
    },
    beforeRouteLeave(to, from, next) {
        if (to.name == "calendar" && 
            to.params.calendarUid == from.params.calendarUid ){
            next()
        }
        else {
            let calendarUid = this.$route.params.calendarUid
            let calendarProps = this.$store.getters.calendarProperties
            router.replace({
                name:'calendar', 
                params:{calendarUid: calendarUid},
                query: calendarProps ? 
                    {
                        w:calendarProps.columnCount, 
                        dt:calendarProps.selectedDate
                    } : 
                    null
            })
        }
    },
    methods: {
        updateCurrentSlot(){
            let slotIndex = this.$route.params.slotId
            
            if (this.eventSlotIsRequested || this.currentSlot && slotIndex == this.currentSlot.index) return
            this.eventSlotIsRequested = true

			let slot = this.$store.getters.getSlotByIndex(slotIndex)
			if (slot) {
                let slots = this.$store.getters.calendarSlotsByDate(slot.period.start.date)
                this.slotsWithSameDate = Object.values(slots)
			}
            
            // Make the request anyway because 
            // the attenders field is required

            CMS.slots.get(this.calendarUid, slotIndex).then((slotInfo) => {

                if (slotInfo.err) 
                    throw slotInfo.err

                let slotDetails = slotInfo.slot

                let date = new Date(slotDetails.startAt)
                let dateInCalendarTZ = convertTZ(date, this.browserTimezone.name, this.calendarTimezone.name)
                let dur = slotDetails.dur

                this.currentSlot = slot
                if (!this.currentSlot)
                    this.currentSlot = new Slot(dateInCalendarTZ, dur, slotDetails)
                this.currentSlot.addAttenders(slotInfo.attenders)
                console.log('this.currentSlot.attenders :>> ', this.currentSlot.attenders);
                this.eventSlotIsRequested = false

                if (this.slotsWithSameDate.length == 0) 
                    this.updateColumnSlots()
            })
        }, 
        updateColumnSlots(){
            let from = this.currentSlot.period.start.date

            // Get slots from one column with current
            CMS.slots.getAll(this.calendarUid, from, from).then(slots => {
                if (slots.err) throw slots.err


                let slotArray = []
                if(slots.items == null) return

                slots.items.forEach(slotInfo => {
                    
                    // date in local timezone 
                    let date = new Date(slotInfo.startAt)
                    let dateInCalendarTZ = convertTZ(date, this.browserTimezone.name, this.calendarTimezone.name)
                    let dur = slotInfo.dur

                    delete slotInfo.startAt
                    delete slotInfo.dur
                    
                    slotArray.push(new Slot(dateInCalendarTZ, dur, slotInfo))
                })
                this.slotsWithSameDate = slotArray.sort((slotA,slotB) => {
                    return slotA.period.start.date - slotB.period.start.date
                })
            }).catch((error)=>{console.error(error)})
        },
        swipeRightHandler(){
            if(this.slotIsLast()) return

            let currentIndex = this.slotIndex()
            let slot = this.slotsWithSameDate[currentIndex+1] 

            CMS.slots.get(this.calendarUid, slot.index)
                .then(slotInfo => {
                    if (slotInfo.err)
                        throw slotInfo.err

                    this.currentSlot = slot
                    this.currentSlot.addAttenders(slotInfo.attenders)
                    this.updateURI()
                })
        },
        swipeLeftHandler(){
            if(this.slotIsFirst()) return

            let currentIndex = this.slotIndex()
            let slot = this.slotsWithSameDate[currentIndex-1] 

            CMS.slots.get(this.calendarUid, slot.index)
                .then(slotInfo => {
                    if (slotInfo.err)
                        throw slotInfo.err

                    this.currentSlot = slot
                    this.currentSlot.addAttenders(slotInfo.attenders)
                    this.updateURI()
                })
        },
        
        slotIsFirst(){
            let index = this.slotIndex()
            if (index == undefined) return false
            return index == 0
        },
        
        slotIsLast(){
            let index = this.slotIndex()
            if (index == undefined) return false
            return index == this.slotsWithSameDate.length - 1
        },

        slotIndex(){
            return this.slotsWithSameDate.findIndex(slot => slot.index == this.currentSlot.index)
        },

        updateURI(){
            let calendarUid = this.$route.params.calendarUid
            router.replace({name: "slot-attend", params: {calendarUid: calendarUid, slotId: this.currentSlot.index}})
        },
    }
}
</script>

<style scoped>
.slot-attend{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}
</style>